.video-controls-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.VideoControls {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.control_Container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 1;
  display: flex;
  justify-content: center;
  border-radius: 25px !important;
  margin-bottom: 10px;
}

.player__wrapper video {
  border-radius: 25px;
}

.top_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 20px;
}

.mid__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom__container {
  position: absolute;
  margin: auto;
  width: -webkit-fill-available;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
   
  );
  border-radius: 0 0 25px 25px;
}

.icon__btn {
  color: white !important;
  transition: all 0.3s ease-in-out !important;
  transform-origin: center;
  width: 150px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}

.mid__container .icon__btn {
  font-size: 60px !important;
  margin-left: 15px;
  margin-right: 15px;
}

.icon__btn:hover {
  transform: scale(1.3);
}

.slider__container {
  /* width: 100%; */
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.control__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5%;
  margin-top: 0;
}

.inner__controls {
  display: flex;
  padding: 10px 0;
  align-items: center;
  width: 60%;
}

.second__control {
  display: flex;
  align-items: center;
}

.timeBox {
  color: white !important;
  width: 100px;
  text-align: center;
}

.video-controls-container .timeBox {
  color: white !important;
  min-width: 100px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0px 0px;
  border-radius: 4px;
}

.PrettoSlider-thumb {
  height: 5px;
  width: 5px;
  background-color: white;
  border: 1px solid currentColor;
  &:focus,
  &:hover,
  &:active {
    box-shadow: inherit;
  }
}

.FullscreenIcon {
  margin-right: 30px !important;
}

.FullscreenExitIcon {
  margin-right: 30px !important;
}

.button-hover-effect:hover {
  background-color: lightgray;
}

.control_Container:fullscreen {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  padding: 0 !important;
  margin: 0 !important;
  max-width: 3000px !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.font-variant-numeric {
  font-variant-numeric: tabular-nums;
  margin-left: 10px;
  color: white !important;
}

@media (max-width: 1024px) {
  .video-container {
    margin-left: 2%;
    margin-right: 2%;
  }
  .icon__btn {
    font-size: 2rem !important;
  }
}

@media (max-width: 960px) {
  .video-container {
    margin-left: 0;
    margin-right: 0;
    padding: 2;
  }
  .icon__btn {
    font-size: 1.5rem !important;
  }
}
@media (max-width: 760px) {
  .video-container {
    margin-left: 2 !important;
    margin-right: 2 !important;
    padding: 1 !important;
  }
  .icon__btn {
    font-size: 1.2rem !important;
  }
}

.slider__container {
  zoom: 133.3%;
}

.card {
  color: black;
}

.timeBox {
  color: white !important;
}

.MuiPaper-root {
  position: absolute;
}

/* Container positioning */
.playspeed-dropdown {
  position: relative;
  display: inline-block;
}

/* Button styling */
.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* Dropdown Content (Hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  bottom: 100%; /* Position above the button */
  left: 0;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Dropdown Links */
.dropdown-content a {
  color: black;

  text-decoration: none;
  display: inline;
  text-align: left;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: inline;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.zoomStyle {
  zoom: 133.3%;
}

.controls-hidden {
  opacity: 0;
  transition: opacity 0.5s ease;
}
